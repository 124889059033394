import { useRouter } from '@/hooks/useRouter';
import { persistor, store } from '@/store';
import { ThemeProvider } from '@/utils/material';
import { PageLoading } from '@propify-tenant-client/common';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { MuiTheme } from './theme';

const App = () => {
  const { router } = useRouter();

  return (
    <Provider store={store}>
      <PersistGate loading={<PageLoading />} persistor={persistor}>
        <ThemeProvider theme={MuiTheme}>
          <RouterProvider router={router} />
          <ToastContainer
            containerId="toast-container"
            position="top-center"
            autoClose={2500}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
